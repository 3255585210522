/** @jsx jsx */
import { jsx, Text, Button, Spinner, Message } from "theme-ui";
import { useQueryParam, StringParam } from "use-query-params";
import ensureSignedInConditional from "gatsby-plugin-hfn-profile/auth/ensureSignedInConditional";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { formatSys2OdooDate } from "sites-common/utils/nextMonth";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { containerStyles } from "sites-common/utils/fabricStyles";
import { useFirestore } from "react-redux-firebase";
import ProfileWidgetCore from "../../components/ProfileWidgetCore";
import { base10 } from "../../lib/base";
import DLWLayout from "../../layouts/dlw-mobile";

const d1 = new Date();
const today0 = formatSys2OdooDate(d1);

// const code = "DLQPWQEBUGKU";
function validateQR(c) {
  try {
    if (!c) {
      return { error: "Invalid url/code" };
    }
    const d = base10(c);
    const exp = d % 100000000;
    const id = Math.floor(d / 100000000);
    if (!id || id < 1) {
      return { error: `Invalid Code` };
    }
    if (exp < 20200000) {
      return { error: "Invalid Code" };
    }
    const e = `${exp}`;
    const expiry = `${e.substring(0, 4)}-${e.substring(4, 6)}-${e.substring(
      6,
      8
    )}`;
    if (expiry < today0) {
      return { error: "Link Expired" };
    }

    return { id };
  } catch (e1) {
    return { error: "Invalid Code" };
  }
}

const goToProfile = () => navigate("/profile/");

const QrSignedInSeeker = ({ pid, code }) => {
  const [pname, setPname] = useState("");
  const { fetchSrcmApi } = useFetchSrcmApi();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (pid && code && fetchSrcmApi) {
      fetchSrcmApi({
        api: `/api/v2/myprofile/preceptors/${pid}/`,
        client: "profileClient",
        method: "GET",
      })
        .then((R) => {
          setLoading(false);
          if (R) {
            setPname(
              `${R.name}, ${R.city}, ${R.country?.name}`.replaceAll("-", " ")
            );
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [pid, code, setLoading, fetchSrcmApi]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <div>
      {/* <Text variant="subtitle"><i>Trainer: </i>{pname}</Text> */}
      <ProfileWidgetCore firstSittingBy={`${pid}-${pname}-${code}`} />
    </div>
  );
};
QrSignedInSeeker.propTypes = {
  pid: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
};

const QrSignedIn = ({ pid, code }) => {
  const { srcmProfile } = useAuth();
  if (srcmProfile?.isAtleastPractitioner) {
    return (
      <div sx={{ m: 3 }}>
        <div>
          You (<b>{srcmProfile?.email}</b>) are already registered as a
          meditation practitioner! <br />
          <br />
        </div>
        <Button onClick={goToProfile} variant="variant1a">
          Go to Profile
        </Button>
      </div>
    );
  }
  return <QrSignedInSeeker pid={pid} code={code} />;
};
QrSignedIn.propTypes = {
  pid: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};
const N = ensureSignedInConditional(QrSignedIn, {
  showSignInWidget: true,
});

const QrNonSignedIn = () => {
  const { srcmProfile } = useAuth();
  if (srcmProfile) {
    return null;
  }
  return (
    <div sx={{ textAlign: "left" }}>
      <Message sx={{ my: 2 }}>
        Create an account using any of the following sign-in methods.{" "}
      </Message>
    </div>
  );
};

const QR = () => {
  const firestore = useFirestore();

  const [code] = useQueryParam("code", StringParam);

  const [code1, setCode1] = useState(code || "");
  const [code2, setCode2] = useState("");

  const { error, id: pid } = validateQR(code1);
  const [e, setE] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (code2.length === 4) {
      setLoading(true);
      firestore
        .get({ collection: "TrainerQrCodes", doc: code2 })
        .then((doc) => {
          if (doc.exists) {
            const d = doc.data();
            const { code: c1 } = d;
            setCode1(c1);
            setLoading(false);
            setE("");
          } else {
            setE("Invalid or Expired Trainer Code");
            setLoading(false);
          }
        });
    }
  }, [code2, setE, setCode1, setLoading, firestore]);

  const setCode2x = useCallback(
    (c) => {
      if (c.length < 5) {
        setCode2(c.toUpperCase());
      }
    },
    [setCode2]
  );

  return (
    <DLWLayout>
      <div sx={{ textAlign: "center" }}>
        <div sx={{ mb: 2 }}>
          <Text variant="header">
            Practitioner Registration using Trainer Code
          </Text>
        </div>

        <div sx={{ pb: 3 }}>
          <div style={containerStyles.flexcenter}>
            {!code1 && (
              <DynamicField
                sx={{ maxWidth: "200px" }}
                name="code2"
                label="Trainer Code"
                onChange={setCode2x}
                value={code ? code1 : code2}
              />
            )}
            {!!code1 && <div>{code2}</div>}
          </div>
          {loading && <Spinner />}
          {error && code1.length > 6 && (
            <div>
              <small sx={{ color: "red" }}>{error}</small>
            </div>
          )}
          {e && (
            <div>
              <small sx={{ color: "red" }}>{e}</small>
            </div>
          )}
        </div>

        {pid && <QrNonSignedIn />}

        {/* {!!code && error && <Alert sx={{ m: 3 }}>{error}</Alert>} */}
        {pid && (
          <div key={pid}>
            <N pid={pid} code={code1} />
          </div>
        )}
      </div>
    </DLWLayout>
  );
};
export default QR;
